export const MenuItems = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "Portfolio",
        url: "portfolio"
    },
    {
        title: "Education",
        url: "education"
    },
    {
        title: "Work",
        url: "work"
    },
    {
        title: "Skills",
        url: "skills"
    }
];
