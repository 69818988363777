import { Link } from 'react-router-dom';
import './Btn.component.css'

function PrimaryBtn(props) {
  return (
    <Link to={props.link} >
      <div className={props.btnType}>
          {props.label}
      </div>
    </Link>
  );
}
export default PrimaryBtn