import PrimaryBtn from "../Buttons/Primary-btns/Primary-btn.component";

import { MenuItems } from "./menuItems";


import "./Navbar.component.css";

import mail from "../../assets/images/mail.png";
import call from "../../assets/images/call.png";
import whatsapp from "../../assets/images/whatsapp.png";
import linkedin from "../../assets/images/linkedin.png";
import github from "../../assets/images/github.png";
import skype from "../../assets/images/skype.png";
import behance from "../../assets/images/behance.png";
import instagram from "../../assets/images/insta.png";
import krLogo from "../../assets/images/kr-logo.png";
import docDownload from "../../assets/images/document-download.png";

import { Link } from "react-router-dom";


function Navbar(props) {

  const downloadCV = ()=>{
    window.open("https://drive.google.com/file/d/1pSpuzRvwW-A4aESzt9EirucjMqQWj5HR/view?usp=sharing", '_blank').focus();
  }

  return (
    <div id="navbars" className={props.mobNavActive?'active': ''}>
      <div className="web-navbar-contacts navbar-contacts">
        <div className="d-flex justify-content-center flex-wrap pt-2 pb-2">
          <div className="myContainer">
            <div className="d-flex justify-content-between">
              <div>
                <div className="contacts d-flex">
                  <div className="mail contact mr-3">
                    <span className="contact-icon mr-1">
                      <img src={mail} alt="kuldeep rawat email" /> 
                    </span>
                    <a href="mailto:kuldeep363rawat@gmail.com">
                      kuldeep363rawat@gmail.com
                    </a>
                  </div>
                  <div className="line mt-auto mb-auto mr-3"></div>
                  <div className="call contact mr-3">
                    <span className="contact-icon mr-1">
                      <img src={call} alt="kuldeep rawat number" />
                    </span>
                    <a href="tel:+917982696231">+91 7982696231</a>
                  </div>
                  <div className="line mt-auto mb-auto mr-3"></div>
                  <div className="whatsapp contact mr-3">
                    <span className="contact-icon mr-1">
                      <img src={whatsapp} alt="kuldeep rawat whatsapp" />
                    </span>
                    <a
                      href="https://api.whatsapp.com/send?phone=917982696231&text=Hello,Kuldeep Rawat!"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 7982696231
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="social-icons d-flex">
                  <div className="linkedin ml-3">
                    <a
                      href="https://www.linkedin.com/in/kuldeep-rawat-877360137/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedin} alt="kuldeep rawat linkedin" />
                    </a>
                  </div>
                  <div className="github ml-3">
                    <a
                      href="https://github.com/Kuldeep363"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={github} alt="kuldeep rawat github" />
                    </a>
                  </div>
                  <div className="skype ml-3">
                    <a
                      href="skype:kuldeep363rawat?add"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={skype} alt="kuldeep rawat skype" />
                    </a>
                  </div>
                  <div className="behance ml-3">
                    <a
                      href="https://www.behance.net/kuldeeprawat2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={behance} alt="kuldeep rawat behance" />
                    </a>
                  </div>
                  <div className="insta ml-3">
                    <a
                      href="https://www.instagram.com/i.am.kuldeep.rawat/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={instagram} alt="kuldeep rawat insta" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* web navbar  */}
      <div d="navbar-links" className="d-flex justify-content-center pt-3 pb-3" >
        <div className="myContainer myNavbar">
          <div className="d-flex justify-content-between">
            <div id="logo" className="mt-auto mb-auto">
              <Link to="/">
                <img src={krLogo} alt="kuldeep rawat" />
              </Link>
            </div>
            <div id="links">
              <div className="d-flex align-items-center">
                <div>
                  <div className="d-flex">
                    <div id="nav-links" className="m-auto">
                      <div className="d-flex">
                        {MenuItems.map((item, index) => {
                          return (
                            <div className="mr-4" key={index}>
                              <Link to={item.url} >{item.title}</Link>
                            </div>
                          );
                        })}
                        <div className="mr-4">
                          <button className="downloadCV"  onClick={downloadCV}>
                            Download CV
                            <span>
                              <img
                                src={docDownload}
                                alt="kuldeep rawat cv"
                                style={{ width: "16px" }}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="hire-btn m-auto">
                      <PrimaryBtn label="Hire me" link="contact-me" btnType="primary-btn" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* mobile links */}
            <div id="mobile-navbar">
              <div className="d-flex">
                <div className="hire-btn mt-auto mb-auto mr-5">
                  <PrimaryBtn label="Hire me" link="contact-me" btnType="primary-btn " />
                </div>
                <div id="menu-toggler" className="d-flex align-items-center"  onClick={props.toggleMobMenu}>
                  <div
                    className={`hamburger-menu ${props.mobNavActive?'active': ''}`}
                    id="hamburger-menu"
                   
                  >
                    <div className="menu-bar1"></div>
                    <div className="menu-bar2"></div>
                    <div className="menu-bar3"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* mobile links end */}
          </div>
        </div>
        <div
          id="mob-navbar-links"
          className={`d-flex align-items-center justify-content-center flex-column me-5 mob-navbar-links ${props.mobNavActive?'active': ''}`}>
          <div className="d-flex justify-content-between mb-3  pt-5">
            <div>
              {MenuItems.map((item, index) => {
                return (
                  <div className="mb-3 text-center" key={index}>
                    <Link to={item.url} onClick={props.toggleMobMenu}>{item.title}</Link>
                  </div>
                );
              })}
              <div className="mb-3 text-center">
                <button className="downloadCV"  onClick={downloadCV}>
                  Download CV
                  <span>
                    <img
                      src={docDownload}
                      alt="kuldeep rawat cv"
                      style={{ width: "16px" }}
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="mob-navbar-contacts navbar-contacts">
            <div className="d-flex justify-content-center  pt-2 pb-2">
              <div className="myContainer">
                <div className="d-flex justify-content-between flex-wrap">
                  <div>
                    <div className="contacts d-flex justify-content-center flex-wrap">
                      <div className="mail contact m-2">
                        <span className="contact-icon mr-1">
                          <img src={mail} alt="kuldeep rawat email" />
                        </span>
                        <a href="mailto:kuldeep363rawat@gmail.com">
                          kuldeep363rawat@gmail.com
                        </a>
                      </div>
                      <div className="call contact m-2">
                        <span className="contact-icon mr-1">
                          <img src={call} alt="kuldeep rawat number" />
                        </span>
                        <a href="tel:+917982696231">+91 7982696231</a>
                      </div>
                      <div className="whatsapp contact m-2">
                        <span className="contact-icon mr-1">
                          <img src={whatsapp} alt="kuldeep rawat whatsapp" />
                        </span>
                        <a
                          href="https://api.whatsapp.com/send?phone=917982696231&text=Hello,Kuldeep Rawat!"
                          target="_blank"
                          rel="noreferrer"
                        >
                          +91 7982696231
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="m-auto">
                    <div className="social-icons d-flex justify-content-center">
                      <div
                        className="linkedin m-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <a href="https://www.linkedin.com/in/kuldeep-rawat-877360137/">
                          <img src={linkedin} alt="kuldeep rawat linkedin" />
                        </a>
                      </div>
                      <div className="github m-2">
                        <a
                          href="https://github.com/Kuldeep363"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={github} alt="kuldeep rawat github" />
                        </a>
                      </div>
                      <div className="skype m-2">
                        <a
                          href="skype:kuldeep363rawat?add"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={skype} alt="kuldeep rawat skype" />
                        </a>
                      </div>
                      <div className="behance m-2">
                        <a
                          href="https://www.behance.net/kuldeeprawat2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={behance} alt="kuldeep rawat behance" />
                        </a>
                      </div>
                      <div className="insta m-2">
                        <a
                          href="https://www.instagram.com/i.am.kuldeep.rawat/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={instagram} alt="kuldeep rawat insta" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
