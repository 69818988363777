import './App.css'

import { Suspense, lazy, useState } from "react";
import Navbar from './components/Navbar/Navbar.component';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
const HomePage = lazy(() => import('./pages/Homepage/Homepage.page'));
const Contactpage = lazy(() => import('./pages/ContactPage/Contactpage'));
const Skills = lazy(() => import('./pages/SkillsPage/Skills'));
const Portfolio = lazy(() => import('./pages/Portfolio/Portfolio.component'));
const MaintainancePage = lazy(() => import('./pages/Others/MaintainancePage'));
const NotFoundPage = lazy(() => import('./pages/Others/NotFoundPage'));
const Work = lazy(() => import('./pages/WorkPage/Work'));

// import Loader from './components/LoadingComponent/Loading.component';
AOS.init();

function App() {

  const [isActive, setActive] = useState(false)
  const handleMenuClick = () => {
    document.querySelector("body").classList.toggle("mob-nav-active");
    setActive(!isActive)
  }
  // let [loading, setLoading] = useState(false)



  return (
    <BrowserRouter>
      {/* <Loader  /> */}
      <Navbar mobNavActive={isActive} toggleMobMenu={handleMenuClick} />
      <Suspense fallback={null}>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='contact-me' element={<Contactpage />} />
          <Route path='skills' element={<Skills />} />
          <Route path='portfolio' element={<Portfolio />} />
          <Route path='education' element={<MaintainancePage />} />
          <Route path='work' element={<Work />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

